<template>
<b-container class="projects-container" id="projects">
    <b-row>
        <b-col class="title top">Projects</b-col>
    </b-row>
    <b-row>
        <b-col class="title left">Projects</b-col>
        <b-col class="accordian">
            <Accordion />
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import Accordion from "./Accordion.vue"

export default {
    name: "Projects",
    components: {
        Accordion
    }

}
</script>

<style scoped>

.projects-container {
    background-color: #313335;
    width: 58%;
    height: max-content;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.title {
    font-size: 33px;
    color: #90538A;
}

.title.top {
    display: none;
}

.title.left {
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .projects-container {
        width: 100%;
    }

    .title.left {
        display: none;
    }

    .title.top {
        display: initial;
        padding-bottom: 10%;
        padding-top: 5%;
    }

    .accordion {
        padding-bottom: 5%;
    }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
    .projects-container {
        width: 100%;
    }

    .title.left {
        display: none;
    }

    .title.top {
        display: initial;
        padding-bottom: 5%;
    }

    .accordion {
        padding-bottom: 5%;
    }
}

@media screen and (min-width: 501px) and (max-width: 900px) {
    .projects-container {
        width: 100%;
    }

    .title.left {
        display: none;
    }

    .title.top {
        display: initial;
        padding-bottom: 3%;
        padding-top: 2%;
    }

    .accordion {
        padding-bottom: 3%;
    }

}

@media screen and (min-width: 901px) and (max-width: 1400px) {
    .projects-container {
        width: 100%;
    }
}


</style>