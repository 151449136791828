<template>
  <div>
    <Navbar />
    <div class="components-container">
      <Introduction class="component introduction" />
      <AboutMe class="component aboutme" />
      <Abilities class="component abilities" />
      <Projects class="component projects" />
      <Contact class="component contact" />
    </div>
    <div class="footer">|  Designed & Built by Russell Pacheco  |</div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue"
import Introduction from "./components/Introduction.vue"
import AboutMe from "./components/AboutMe.vue"
import Abilities from "./components/Abilities.vue"
import Projects from "./components/Projects.vue"
import Contact from "./components/Contact.vue"

export default {
  name: 'App',
  components: {
    Navbar,
    Introduction,
    AboutMe,
    Abilities,
    Projects,
    Contact
  }
}
</script>

<style>
@font-face {
  font-family: JetBrains;
  src: url("./assets/Font/JetBrainsMono-Regular.woff");
}
body {
  font-family: JetBrains;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #2b2b2b;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.components-container {
  display: flex;
  flex-direction: column;
}

.component {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%
}

.component.introduction {
  margin-left: 26%;
}

.footer {
  padding-bottom: 1%;
  color: white;
  font-size: 8px;
}

@media screen and (min-width: 300px) and (max-width: 400px) {
  .component {
    margin-bottom: 5%;
  }

  .footer {
    padding-top: 5%;
  }
  
}


</style>
